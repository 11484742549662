import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  DocketAPIAlreadyEnqueuedError,
  DocketAPIError,
  getAPIClient,
  LegalSex,
  legalSexFromString,
} from "../../apiClient";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { infoLog } from "../../utils/logger";
import {
  checkBoxValueMap,
  getLocalizedLegalSexChoices,
  getWhoAmIChoices,
  getWhoAmiRadios,
  getHorizontalRadioSection,
  SearchFormSchema,
  getWhoAmILegacy,
  getWhoAmIChildChoices,
  Radios,
} from "../../utils/formUtils";
import "./searchForm.css";
import DatePicker from "../../components/form/DatePicker";
import moment from "moment";
import SearchFormHeader from "../../components/search/SearchFormHeader";
import lodash from "lodash";
import { ImmunizationNewSearch } from "../../models/Interfaces";
import { readableDate } from "../../utils/data";
import {
  akSearchFooter,
  idSearchFooter,
  mnSearchFooter,
  njSearchFooter,
  utSearchFooter,
} from "../../components/LegalFooters";
import { ErrorModal } from "../../components/modals/ErrorModal";
import {
  enqueuedIzSearchAtom,
  izProviderConfigsAtom,
  izSearchAPIResultAtom,
  selectedSearchAtom,
  userAtom,
} from "../../jotai/atoms";
import { useAtom } from "jotai";
import { fetchRecordsUntilUpdated } from "../../utils/polling";
import { SUBDOMAIN, WHITELABEL_KEY } from "../../globals";
import i18n from "../../i18n";
import { LegacyEnqueueSearchRequestDto } from "@docket/consumer-app-client-typescript-axios";

export function SearchForm() {
  const navigate = useNavigate();
  // searches selector to update UI
  const [existingSearch, setExistingSearch] = useAtom(selectedSearchAtom);
  const [user] = useAtom(userAtom);
  const [_izSearchAPIResult, setIzSearchAPIResult] = useAtom(izSearchAPIResultAtom);
  const [_, setEnqueuedIzSearch] = useAtom(enqueuedIzSearchAtom);
  const [providerConfigs] = useAtom(izProviderConfigsAtom);
  const { t } = useTranslation();
  // patient or parent DOB
  const [dob, setDob] = useState<Date | null>(
    existingSearch
      ? moment(existingSearch.dateOfBirth).toDate()
      : user?.dob
      ? moment(user.dob).toDate()
      : moment().subtract(18, "years").toDate()
  );

  // Zipcode state
  const [zipcode, setZipcode] = useState<string>(existingSearch?.zipcode || "");
  const [zipcodeError, setZipcodeError] = useState<string>("");
  const [showZipcode, setShowZipcode] = useState<boolean>(false);
  const [zipcodeRequired, setZipcodeRequired] = useState<boolean>(false);

  // child DOB
  const [cDob, setCDob] = useState<Date | null>(
    existingSearch && existingSearch.childDateOfBirth
      ? moment(existingSearch.childDateOfBirth).toDate()
      : new Date()
  );
  // DOB checkbox, to map selection to values
  const [legalSexCheckBoxes, setLegalSexCheckBoxes] = useState(getLocalizedLegalSexChoices(t));
  // Child DOB checkbox, to map selection to values
  const [childLegalSexCheckBoxes, setChildLegalSexCheckBoxes] = useState(
    getLocalizedLegalSexChoices(t)
  );
  // who am I checkbox, to map selection to values
  // ToDo: set initial state from prev search or user object
  const [whoAmICheckBoxes, setWhoAmICheckBoxes] = useState(getWhoAmIChoices(t));
  const [gaveConsent, setGaveConsent] = useState(false);
  const [showChildSearch, setShowChildSearch] = useState(
    existingSearch && existingSearch.childFirstName ? true : false
  );
  const [provider, setProvider] = useState("");
  const providerKey = useRef("");
  const prevProvider = useRef("");

  useEffect(() => {
    // if subdomain equals a state abbreviation exactly
    const supportedSubdomains = providerConfigs.map((config) =>
      config.iz_provider.destination_id.toLowerCase()
    );

    if (supportedSubdomains.includes(SUBDOMAIN)) {
      setProvider(t(`discover.page_${SUBDOMAIN}`) || "");
    } else {
      // NJ whitelabel which does not necessarily equal 'nj'
      const defaultProvider = WHITELABEL_KEY === "nj" ? t(`discover.page_nj`) : "";
      setProvider(defaultProvider || "");
    }
  }, [providerConfigs]);

  // Effect to determine if zipcode is required based on provider
  // Combine the two effects into one

  useEffect(() => {
    if (!provider || provider.length === 0) {
      providerKey.current = "";
      setShowZipcode(false);
      setZipcodeRequired(false);
      return;
    }

    let destination_id = "";
    for (const value of Object.values(providerConfigs)) {
      if (value.enabled && provider.includes(value.iz_provider.name)) {
        destination_id = value.iz_provider.destination_id;
        break;
      }
    }

    if (destination_id.length > 0) {
      providerKey.current = destination_id;
    } else {
      providerKey.current = "";
    }

    const selectedProvider = providerConfigs.find(
      (config) =>
        config.iz_provider.destination_id.toLowerCase() === providerKey.current.toLowerCase()
    );

    const hasZipcode =
      selectedProvider &&
      selectedProvider.additional_fields &&
      selectedProvider.additional_fields.zipcode;

    setShowZipcode(!!hasZipcode);

    const isRequired = hasZipcode && selectedProvider.additional_fields.zipcode.required === true;
    setZipcodeRequired(!!isRequired);
  }, [provider, providerConfigs]);

  const handleZipcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Filter out non-numeric characters and limit to 5 digits
    const filteredValue = e.target.value.replace(/[^0-9]/g, "").slice(0, 5);
    setZipcode(filteredValue);

    if (filteredValue.length === 5 || (!zipcodeRequired && filteredValue.length === 0)) {
      setZipcodeError("");
    }
  };

  const validateZipcode = (value: string) => {
    if (zipcodeRequired && (!value || value.trim() === "")) {
      return t("immunizations.search_check_zipcode_required");
    }

    if (value && !/^\d{5}$/.test(value)) {
      if (!zipcodeRequired && value.trim() === "") {
        // Optional zip code and the field is empty
        // If it ISN'T empty, we still want to validate it.
        return "";
      }
      return t("immunizations.search_check_zipcode_format");
    }
    return "";
  };

  const handleZipcodeBlur = () => {
    const error = validateZipcode(zipcode);
    setZipcodeError(error);
  };

  const checkboxHandler = (value: any, index: number) => {
    const newValue = checkBoxValueMap(legalSexCheckBoxes, index);
    setLegalSexCheckBoxes(newValue);
  };
  // Child DOB checkbox, to map selection to values
  const childCheckboxHandler = (value: any, index: number) => {
    const newValue = checkBoxValueMap(childLegalSexCheckBoxes, index);
    setChildLegalSexCheckBoxes(newValue);
  };
  // who am I checkbox, to map selection to values
  // ToDo: set initial state from prev search or user object
  const whoAmIHandler = (value: any, index: number) => {
    infoLog(`whoAmIHandler ${value} ${index}`);
    const newValue = checkBoxValueMap(whoAmICheckBoxes, index);
    setWhoAmICheckBoxes(newValue);
  };
  const handleConsentChange = (checked: boolean) => {
    setGaveConsent(checked);
  };

  // Handle provider change - reset zipcode when changing providers
  const handleProviderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setProvider(e.target.value);
    setZipcode("");
    setZipcodeError("");
  };

  type ErrorModalParams = {
    title: string;
    body: string;
    show: boolean;
  };
  const [showErrorModal, setShowErrorModal] = useState<ErrorModalParams>({
    title: "",
    body: "",
    show: false,
  });
  const [title, message] = t("immunizations.search_check_search_in_queue").split("|");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(SearchFormSchema),
  });

  const searchFormTranslations = {
    firstName: t("immunizations.search_my_first_name_label"),
    lastName: t("immunizations.search_my_last_name_label"),
    dateOfBirth: t("immunizations.search_my_dob_label"),
    legalSex: t("immunizations.search_my_legal_sex_label"),
    zipCode: t("immunizations.search_zip_code"),
    childFirstName: t("immunizations.search_child_first_name_label"),
    childLastName: t("immunizations.search_child_last_name_label"),
    childDateOfBirth: t("immunizations.search_child_dob_label"),
    childLegalSex: t("immunizations.search_child_legal_sex_label"),
    consentTitle: t("immunizations.search_consent_title"),
    consentMessage: t("immunizations.search_consent_message"),
    consentCheckBoxLabel: t("immunizations.i_consent"),
  };

  useEffect(() => {
    if (!provider || provider.length === 0) {
      providerKey.current = "";
      return;
    }

    let destination_id = "";
    for (const value of Object.values(providerConfigs)) {
      if (value.enabled && provider.includes(value.iz_provider.name)) {
        destination_id = value.iz_provider.destination_id;
        break;
      }
    }
    infoLog(`provider '${destination_id}'`);
    if (destination_id.length > 0) {
      providerKey.current = destination_id;
    } else {
      providerKey.current = "";
    }
  }, [provider]);

  useEffect(() => {
    if (existingSearch?.dateOfBirth) {
      setDob(moment(existingSearch.dateOfBirth).toDate());
    } else if (user?.dob) {
      setDob(moment(user.dob).toDate());
    }
    return () => {};
  }, [existingSearch, user]);

  useEffect(() => {
    if (existingSearch?.zipcode) {
      setZipcode(existingSearch.zipcode);
    }
    return () => {};
  }, [existingSearch]);

  useEffect(() => {
    setLegalSexCheckBoxes(
      checkBoxRenderHelper(legalSexCheckBoxes, [
        existingSearch?.legalSex,
        legalSexFromString(user?.legal_sex || null),
      ])
    );
  }, [existingSearch, user]);

  useEffect(() => {
    setChildLegalSexCheckBoxes(
      checkBoxRenderHelper(childLegalSexCheckBoxes, [existingSearch?.childLegalSex])
    );
  }, [existingSearch]);

  useEffect(() => {
    setWhoAmICheckBoxes(checkBoxRenderHelper(whoAmICheckBoxes, [existingSearch?.whoAmI]));
  }, [existingSearch, user]);

  useEffect(() => {
    // reset was the only way to get hook form to see the form values as valid
    reset({});
  }, [existingSearch, user]);

  const onSubmit = async (data: any) => {
    infoLog(`onSubmit ${JSON.stringify(data)}`);
    // This will control the error modal as well, so we don't need to worry about it here.
    const dobsValid = await checkDates();
    //let checkDup = await checkForDuplicateSearch(data)
    const sex = lodash.find(legalSexCheckBoxes, ["checked", true]);
    const csex = lodash.find(childLegalSexCheckBoxes, ["checked", true]);
    const who = lodash.find(whoAmICheckBoxes, ["checked", true]);
    const legalSex = sex?.value ?? null;
    const cLegalSex = csex?.value ?? null;

    // Validate child search data first since it appears at the top of the form
    if (showChildSearch) {
      let errorMsg = "";
      if (!data.childFirstName) {
        errorMsg = t("immunizations.search_check_child_first_name");
      } else if (!data.childLastName) {
        errorMsg = t("immunizations.search_check_child_last_name");
      } else if (!cLegalSex) {
        errorMsg = t("immunizations.search_check_child_legal_sex");
      } else if (!cDob) {
        errorMsg = t("immunizations.search_check_child_dob");
      }

      if (errorMsg.length > 0) {
        setShowErrorModal({
          title: t("immunizations.search_check_header"),
          body: errorMsg,
          show: true,
        });
        return;
      }
    }

    // Add zipcode validation
    if (showZipcode) {
      const zipcodeValidationError = validateZipcode(zipcode);
      if (zipcodeValidationError) {
        setShowErrorModal({
          title: t("immunizations.search_check_header"),
          body: zipcodeValidationError,
          show: true,
        });
        return;
      }
    }

    let errorMsg = "";
    if (!data.firstName) {
      errorMsg = t("immunizations.search_check_first_name");
    } else if (!data.lastName) {
      errorMsg = t("immunizations.search_check_last_name");
    } else if (!legalSex) {
      errorMsg = t("immunizations.search_check_legal_sex");
    } else if (!showChildSearch && !who) {
      // Child searches can only be guardians, so we skip this check since it's auto-selected for the user.
      errorMsg = t("immunizations.search_check_who_am_i");
    } else if (!gaveConsent) {
      errorMsg = t("immunizations.search_check_consent");
    } else if (!dob) {
      errorMsg = t("immunizations.search_check_dob");
    } else if (!providerKey.current || providerKey.current.length == 0) {
      errorMsg = t("immunizations.search_check_provider");
    }

    if (errorMsg.length > 0) {
      setShowErrorModal({
        title: t("immunizations.search_check_header"),
        body: errorMsg,
        show: true,
      });
      return;
    }

    if (dobsValid) {
      // infoLog(JSON.stringify(data));
      // await dispatch(immunizationActions.setSearch(null))
      await runSearch(data, showChildSearch);
    }
  };

  const checkDates = async () => {
    let params: ErrorModalParams = {
      title: "",
      body: "",
      show: false,
    };

    // no child searches if the child is over 18...
    if (showChildSearch && moment().subtract(18, "years").isAfter(moment(cDob), "day")) {
      params = {
        title: t("immunizations.search_age_error_child_title"),
        body: t("immunizations.search_age_error_child_18"),
        show: true,
      };
    }
    // no search if the adult is under 18...
    else if (moment().subtract(18, "years").isBefore(moment(dob), "day")) {
      params = {
        title: t("immunizations.search_age_error_title"),
        body: t("immunizations.search_age_error_18"),
        show: true,
      };
    }

    if (params.show) {
      setShowErrorModal(params);
    }
    return !params.show;
  };

  const runSearch = async (data: ImmunizationNewSearch, childSearch: boolean) => {
    let sex = lodash.find(legalSexCheckBoxes, ["checked", true]);
    let whoami = !showChildSearch
      ? lodash.find(whoAmICheckBoxes, ["checked", true])
      : { value: "guardian" };
    let childLegalSex = showChildSearch
      ? lodash.find(childLegalSexCheckBoxes, ["checked", true])
      : { value: "" };
    data.dateOfBirth = readableDate(dob || "");
    data.legalSex = sex ? sex.value : "U";
    data.childLegalSex = childSearch ? childLegalSex!.value : undefined;
    data.childDateOfBirth = childSearch ? readableDate(cDob || "") : undefined;
    data.childFirstName = childSearch
      ? data.childFirstName
        ? data.childFirstName.trim()
        : ""
      : undefined;
    data.childLastName = childSearch
      ? data.childLastName
        ? data.childLastName.trim()
        : ""
      : undefined;
    data.whoAmI = whoami!.value;
    data.izProviderKey = providerKey.current;
    data.zipcode = showZipcode ? zipcode : undefined;

    let searchObj: LegacyEnqueueSearchRequestDto & { iz_provider_key: string; zipcode?: string } = {
      first_name: data.firstName.trim(),
      last_name: data.lastName.trim(),
      dob: data.dateOfBirth,
      legal_sex: data.legalSex as LegalSex,
      child_first_name: data.childFirstName,
      child_last_name: data.childLastName,
      child_dob: data.childDateOfBirth,
      child_legal_sex: data.childLegalSex as LegalSex,
      who_am_i: data.whoAmI,
      iz_provider_key: data.izProviderKey,
    };

    if (showZipcode && zipcode) {
      searchObj.zipcode = zipcode;
    }

    //setIsSearchRunning(true)
    try {
      const response = await getAPIClient().enqueueIzSearch(searchObj, searchObj.iz_provider_key);
      await setEnqueuedIzSearch(response);
      /**
       * Do not await this since it could potentially take a long time to
       * complete and we want to navigate immediately.
       */
      fetchRecordsUntilUpdated();
      /**
       * Clear the existing search since we might be creating a new search from
       * a NO_MATCH and we don't want to be taken to the troubleshooting page.
       */
      await setExistingSearch(null);
      navigate("../results", { state: { isScenarioB: false } });
    } catch (e: unknown) {
      if (e instanceof DocketAPIAlreadyEnqueuedError) {
        setShowErrorModal({
          title: t("immunizations.search_check_search_in_queue").split("|")[0],
          body: t("immunizations.search_check_search_in_queue").split("|")[1],
          show: true,
        });
      } else if (e instanceof DocketAPIError) {
        setShowErrorModal({
          title: t("generic.error"),
          body: e.message,
          show: true,
        });
      }
    }
  };

  const runDeleteSearch = async () => {
    if (!existingSearch) {
      return;
    }

    try {
      //      infoLog(`delete search w uid ${existingSearch.uid}`);
      await getAPIClient().deleteIzSearch(existingSearch.uid, existingSearch.izProviderKey);
      const updatedSearches = await getAPIClient().getIzSearches(false);
      await setIzSearchAPIResult(updatedSearches.data);
      setExistingSearch(null);
      navigate(-1);
    } catch (e: unknown) {
      if (e instanceof DocketAPIError) {
        setShowErrorModal({
          title: t("immunizations.search_check_search_not_deleted"),
          body: e.message,
          show: true,
        });
      }
    }
  };

  const getInputField = (
    yupField: "firstName" | "lastName" | "childFirstName" | "childLastName",
    displayName: string,
    defaultVal: string = ""
  ) => {
    return (
      <div key={yupField}>
        <div className="field">
          <label className="label form-input-label">
            {displayName} <span className="required-tag"> ({t("system.required")})</span>
          </label>
          <div className="control has-background-white">
            <input
              data-testid={`input-field-${yupField}`}
              className={`input has-text-dark ${errors[yupField] ? "is-danger" : ""}`}
              type="text"
              placeholder={displayName}
              defaultValue={defaultVal}
              {...register(yupField)}
            />
          </div>
        </div>
        <p className="help is-danger">{errors[yupField] && t("system.required_error")}</p>
      </div>
    );
  };

  const getZipcodeField = () => {
    return (
      <div className="column is-third">
        <div className="field">
          <label className="label form-input-label">
            {searchFormTranslations.zipCode}{" "}
            {!zipcodeRequired ? (
              <span className="required-tag">({t("system.optional")})</span>
            ) : (
              <span className="required-tag">({t("system.required")})</span>
            )}
          </label>
          <div className="control has-background-white">
            <input
              data-testid="input-field-zipcode"
              className={`input has-text-dark ${zipcodeError ? "is-danger" : ""}`}
              type="text"
              placeholder={searchFormTranslations.zipCode}
              value={zipcode}
              onChange={handleZipcodeChange}
              onBlur={handleZipcodeBlur}
              maxLength={5}
              inputMode="numeric"
            />
          </div>
          {zipcodeError && (
            <p className="help is-danger" data-testid="zipcode-error">
              {zipcodeError}
            </p>
          )}
        </div>
      </div>
    );
  };

  const getParentForm = () => {
    return (
      <>
        {showChildSearch && (
          <h1 className="is-text-dark is-size-5 mb-4">
            {t("immunizations.search_parent_guardian_header")}
          </h1>
        )}
        <div className="columns">
          <div className="column is-third">
            {getInputField(
              "firstName",
              searchFormTranslations.firstName,
              existingSearch?.firstName
                ? existingSearch?.firstName
                : user?.first_name
                ? user.first_name
                : ""
            )}
          </div>
          <div className="column is-third">
            {getInputField(
              "lastName",
              searchFormTranslations.lastName,
              existingSearch?.lastName
                ? existingSearch?.lastName
                : user?.last_name
                ? user.last_name
                : ""
            )}
          </div>
          <div className="column is-third">
            <p className="label form-input-label">
              {searchFormTranslations.dateOfBirth}{" "}
              <span className="required-tag"> ({t("system.required")})</span>
            </p>
            <DatePicker selectedDate={dob} setSelectedDate={(d) => setDob(d)} />
          </div>
        </div>

        {showZipcode && (
          <div className="columns">
            {getZipcodeField()}
            <div className="column is-two-thirds"></div>
          </div>
        )}

        <div>
          {getHorizontalRadioSection(
            legalSexCheckBoxes,
            searchFormTranslations.legalSex,
            checkboxHandler,
            "checkbox",
            "gender",
            t
          )}
          <br />
        </div>
      </>
    );
  };

  /**
   * We render a lot of check boxes in the form, often needing to ensure a
   * default is selected. This render helper takes the checkboxes to render as
   * well as an ordered list of preferred defaults. If one of the check boxes is
   * already checked, then the checkboxes are returned as is. If none are
   * checked, then the first defined default is selected.
   */
  const checkBoxRenderHelper = (checkboxes: Radios[], possible: (string | undefined)[]) => {
    if (checkboxes.find((box) => box.checked)) {
      return checkboxes;
    }
    const replacement = possible.find((p) => p !== undefined);
    if (replacement) {
      return checkBoxValueMap(
        checkboxes,
        lodash.findIndex(checkboxes, function (item) {
          return item.value === replacement;
        })
      );
    }
    return checkboxes;
  };

  const getChildForm = () => {
    return (
      <>
        <h1 className="is-text-dark is-size-5 mb-4">{t("immunizations.search_child_header")}</h1>
        <div className="columns">
          <div className="column is-third">
            {getInputField(
              "childFirstName",
              searchFormTranslations.childFirstName,
              existingSearch?.childFirstName ? existingSearch?.childFirstName : ""
            )}
          </div>
          <div className="column is-third">
            {getInputField(
              "childLastName",
              searchFormTranslations.childLastName,
              existingSearch?.childLastName ? existingSearch?.childLastName : ""
            )}
          </div>
          <div className="column is-third">
            <p className="label form-input-label">
              {searchFormTranslations.childDateOfBirth}{" "}
              <span className="required-tag"> ({t("system.required")})</span>
            </p>

            <DatePicker selectedDate={cDob} setSelectedDate={(d) => setCDob(d)} />
          </div>
        </div>
        <div>
          {getHorizontalRadioSection(
            childLegalSexCheckBoxes,
            searchFormTranslations.childLegalSex,
            childCheckboxHandler,
            "checkbox",
            "childGender",
            t
          )}
          <br />

          <hr />
          <br />
        </div>
      </>
    );
  };

  const getConsentForm = () => {
    return (
      <div key={"consent"}>
        <br />
        <p className="label form-input-label mt-5">{searchFormTranslations.consentTitle}</p>
        <br />
        <p className="mb-5 has-text-dark">{searchFormTranslations.consentMessage}</p>
        <label className="checkbox mb-5 has-text-dark">
          <input
            data-testid="consent-checkbox"
            type="checkbox"
            name={searchFormTranslations.consentCheckBoxLabel}
            checked={gaveConsent}
            onChange={(e) => handleConsentChange(e.target.checked)}
          />
          &nbsp;&nbsp;{searchFormTranslations.consentCheckBoxLabel}
        </label>
      </div>
    );
  };

  return (
    <div className="mb-6">
      {showErrorModal.show && (
        <ErrorModal
          title={showErrorModal.title}
          onCloseModal={() =>
            setShowErrorModal((cur: ErrorModalParams): ErrorModalParams => {
              return { title: cur.title, body: cur.body, show: false };
            })
          }
        >
          <p>{showErrorModal.body}</p>
        </ErrorModal>
      )}
      <SearchFormHeader
        deleteSearch={existingSearch ? runDeleteSearch : () => {}}
        showChildSearch={showChildSearch}
        showChild={setShowChildSearch}
        showDelete={!!existingSearch}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="columns">
          <div className="column is-0" />
          <div className="column has-text-dark">
            <div className="form-container has-dark-text">
              <p className="label form-input-label">
                {t("immunizations.search_state_registry_label")}
              </p>
              <div className="select mb-5">
                <select
                  data-testid="jurisdiction-selector"
                  value={provider}
                  onChange={handleProviderChange}
                >
                  <option key="select-provider">{t("immunizations.select_provider")}</option>
                  {providerConfigs
                    .sort((a, b) => (a.iz_provider.name < b.iz_provider.name ? -1 : 1))
                    .map((providerConfig) => (
                      <option key={providerConfig.iz_provider.name}>
                        {i18n.exists(`discover.page_${providerConfig.iz_provider.destination_id}`)
                          ? t(`discover.page_${providerConfig.iz_provider.destination_id}`)
                          : providerConfig.iz_provider.name}
                      </option>
                    ))}
                </select>
              </div>
              {showChildSearch && getChildForm()}
              {getParentForm()}
              {showChildSearch
                ? getWhoAmiRadios(
                    getWhoAmIChildChoices(t),
                    t("immunizations.i_am"),
                    whoAmIHandler,
                    t
                  )
                : getWhoAmiRadios(whoAmICheckBoxes, t("immunizations.i_am"), whoAmIHandler, t)}
              <hr />
              {getConsentForm()}

              <br />
              <button
                type="submit"
                data-testid="search-form-button"
                className="button mt-5 docket-button pl-5 pr-5 is-pulled-right mb-5"
              >
                {t("immunizations.search_records_button")}
              </button>

              <br />
              <br />

              <br />
              {
                // TODO: Move these into the IzProvider
              }
              {provider.includes("Alaska") && akSearchFooter(t)}
              {provider.includes("Idaho") && idSearchFooter(t)}
              {provider.includes("Minn") && mnSearchFooter(t)}
              {provider.includes("New Jersey") && njSearchFooter(t)}
              {provider.includes("Utah") && utSearchFooter(t)}
            </div>
          </div>
          <div className="column is-0" />
        </div>
      </form>
    </div>
  );
}

export default SearchForm;
