import { Atom, atom, WritableAtom } from "jotai";
import {
  AppSettings,
  ImmunizationRecord,
  ImmunizationSearch,
  SCREENING_TYPE,
  ScreeningRecord,
  ScreeningResult,
  SearchStatus,
  Tokens,
  UserInfo,
} from "../models/Interfaces";
import { atomWithStorage, atomFamily, loadable, RESET } from "jotai/utils";
import { EncryptedAsyncStorageDBAdapter, UnencryptedAsyncStorageDBAdapter } from "./storage";
import { Key } from "../database";
import { createNewRecord, createSearches } from "../hooks/getSearchesUtil";
import {
  LegacyEnqueueSearchResponseDto,
  LegacyProviderConfigDto,
  ScreeningDto,
  ScreeningDtoTypeEnum,
} from "@docket/consumer-app-client-typescript-axios";
import { LegacyGetRecordDataDtoWithIntId, LegacyGetSearchDataDtoWithIntId } from "../apiClient";

type SetStateActionWithReset<Value> =
  | Value
  | typeof RESET
  | ((prev: Value) => Value | typeof RESET);

export type CompleteRecord = {
  search: ImmunizationSearch;
  record: ImmunizationRecord | null;
  screening: ScreeningRecord | null;
};

export type CompleteVerifiedRecord = CompleteRecord & { record: ImmunizationRecord };

export function atomWithStorageDB<T>(
  key: string,
  initVal: T,
  options?: { getOnInit?: boolean }
): WritableAtom<T | Promise<T>, [SetStateActionWithReset<T | Promise<T>>], Promise<void>> {
  return atomWithStorage<T>(key, initVal, new EncryptedAsyncStorageDBAdapter<T>(), options);
}

export function atomWithUnencryptedStorageDB<T>(
  key: string,
  initVal: T,
  options?: { getOnInit?: boolean }
): WritableAtom<T | Promise<T>, [SetStateActionWithReset<T | Promise<T>>], Promise<void>> {
  return atomWithStorage<T>(key, initVal, new UnencryptedAsyncStorageDBAdapter<T>(), options);
}

export const appSettingsAtom = atomWithUnencryptedStorageDB<AppSettings>("appSettings", {
  firebaseEmulatorConnected: false,
  email: "",
  authorized: false,
  signedInWithFirebase: false,
});

/**
 * The atom that holds the stored user info object. We don't export
 * this atom directly, as it shouldn't be used directly in the application. The
 * app can instead use the exported derived atoms built on top of this in order
 * to interact with the user data.
 */
const storedUserInfoAtom = atomWithStorageDB<UserInfo | null>(Key.UserInfo, null);

/**
 * A derived atom to get/set the user account info. This atom ensures that we
 * always delete the legacy authentication token from the user so it is not
 * stored.
 */
export const userAtom = atom(
  (get) => get(storedUserInfoAtom),
  async (_get, set, updatedUser: UserInfo | null) => {
    if (updatedUser && "token" in updatedUser) {
      delete (updatedUser as any).token;
    }
    return set(storedUserInfoAtom, updatedUser);
  }
);

/**
 * This write-only derived user atom is helpful when you need to set the user
 * account info but have not yet unlocked the encrypted database.
 *
 * For example, if you were to use the read/write user atom on a page that does
 * not unlock the DB before rendering (eg the login page), and had already
 * loaded the application in another tab, then Jotai would try to fetch the
 * stored user info on render and fail (since the DB is not yet unlocked).
 */
export const writeOnlyUserAtom = atom(null, async (_get, set, update: UserInfo | null) => {
  await set(userAtom, update);
});

/**
 * We create a dedicated Atom to hold a user's tokens. Tokens and user information are delivered in three different
 * ways via the APIs in a such a way that you might get both or only one:
 *
 * - The login APIs deliver UserInfo & Tokens
 * - The GET user API delivers only UserInfo
 * - The refresh tokens API delivers only Tokens
 *
 * It's difficult to apply our strategy of holding entire API responses in one dedicated Atom since tokens and user info
 * are sometimes delivered separately. We _could_ implement storage with a single storage atom that hold the user info
 * and tokens together. We'd then expose derived atoms that are smart enough to update the relevant data depending on
 * which of the 3 combinations of data are used in the update. Creating separate storage atoms, however, seems like a
 * cleaner way to do this. Especially, since, in an ideal world, I think the login APIs should only deliver tokens and
 * then user info would be fetched as a followup to login.
 */
export const tokensAtom = atomWithStorageDB<Tokens | null>("tokens", null);

/**
 * This write-only derived tokens atom is helpful when you need to set the tokens
 * but have not yet unlocked the encrypted database.
 */
export const writeOnlyTokensAtom = atom(null, async (_get, set, update: Tokens | null) => {
  await set(tokensAtom, update);
});

export const izProviderConfigsAtom = atomWithStorageDB<LegacyProviderConfigDto[]>(
  "providerConfigs",
  []
);
export const selectedSearchAtom = atomWithStorageDB<ImmunizationSearch | null>(
  "selectedSearch",
  null
);

export const izSearchAPIResultAtom = atomWithStorageDB<LegacyGetSearchDataDtoWithIntId[]>(
  "apiIzSearches",
  []
);

export const enqueuedIzSearchAtom = atomWithStorageDB<LegacyEnqueueSearchResponseDto | null>(
  "enqueuedIzSearch",
  null
);

// Perhaps this function should live in a helper some where else, but for now it's most applicable here.
export function isSearchVerified(izs: ImmunizationSearch): boolean {
  return (
    izs.status !== SearchStatus.noMatch &&
    izs.status !== SearchStatus.basicMatchNoContacts &&
    izs.dateVerified !== undefined &&
    izs.dateVerified !== ""
  );
}

// Derivative atoms
export const izSearchesAtom = atom(async (get) => {
  return createSearches(await get(izSearchAPIResultAtom));
});

export const verifiedSearchesAtom = atom(async (get) => {
  const searches = await get(izSearchesAtom);
  return searches.filter((izs) => isSearchVerified(izs));
});

export const izRecordAPIResultAtomFamily = atomFamily((searchUid: string) =>
  atomWithStorageDB<LegacyGetRecordDataDtoWithIntId | null>(`apiRecords_${searchUid}`, null)
);

// We map searches to both records and screenings at once.
// Realistically this is just input to the completeVerifiedRecordsAtom and
// it works as a starting point for the canonical view of UI records.
// TODO: Eventually remove `izSearchesAtom` in favor of this data structure.
export const completeRecordsAtom = atom(async (get) => {
  const searches = await get(izSearchesAtom);
  const result: CompleteRecord[] = [];
  for (const search of searches) {
    const fullRecord: CompleteRecord = {
      search: search,
      record: null,
      screening: null,
    };

    const apiRecord = await get(izRecordAPIResultAtomFamily(search.uid));
    if (apiRecord) {
      const record = createNewRecord(search, apiRecord);
      fullRecord.record = record;
    }

    const apiScreeningEvents: ScreeningDto[] = await get(screeningsAPIResultAtomFamily(search.uid));
    const eventsByType: Record<SCREENING_TYPE, ScreeningDto[]> = {} as Record<
      SCREENING_TYPE,
      ScreeningDto[]
    >;
    if (apiScreeningEvents && apiScreeningEvents.length > 0) {
      fullRecord.screening = {
        searchUid: search.uid,
        izProviderId: search.izProviderId,
        izProviderKey: search.izProviderKey,
        results: [],
        firstName: search.isChildSearch ? search.childFirstName : search.firstName,
        lastName: search.isChildSearch ? search.childLastName : search.lastName,
      };

      for (const screeningEvent of apiScreeningEvents) {
        let screeningType: SCREENING_TYPE = "lead";
        if (
          [
            ScreeningDtoTypeEnum.Lead,
            ScreeningDtoTypeEnum.LeadCapillary,
            ScreeningDtoTypeEnum.LeadVenous,
          ].includes(screeningEvent.type)
        ) {
          screeningType = "lead";
        } else {
          throw new Error("Unknown screening type");
        }

        if (!eventsByType[screeningType]) {
          eventsByType[screeningType] = [];
        }
        eventsByType[screeningType].push(screeningEvent);
      }

      // Process each set of types of events
      for (const [k, v] of Object.entries(eventsByType)) {
        const sortedEvents = v.sort(
          (a, b) => new Date(b.testDate || 0).getTime() - new Date(a.testDate || 0).getTime()
        );
        const latestTestDate = sortedEvents[0].testDate;
        const screeningResult: ScreeningResult = {
          type: k as SCREENING_TYPE,
          events: sortedEvents,
          latestTestDate: latestTestDate || undefined,
        };
        fullRecord.screening!.results.push(screeningResult);
      }
    }

    result.push(fullRecord);
  }
  return result;
});

export const completeVerifiedRecordsAtom: Atom<Promise<CompleteVerifiedRecord[]>> = atom(
  async (get) => {
    const completeRecords = await get(completeRecordsAtom);
    const verifiedRecords = completeRecords.filter((r) => isSearchVerified(r.search));
    const results: CompleteVerifiedRecord[] = [];
    for (const vr of verifiedRecords) {
      if (vr.record === null) {
        continue;
      }

      results.push({
        search: vr.search,
        record: vr.record,
        screening: vr.screening,
      });
    }
    return results;
  }
);

export const screeningsAPIResultAtomFamily = atomFamily((searchUid: string) =>
  atomWithStorageDB<ScreeningDto[]>(`apiScreenings_${searchUid}`, [])
);

export const currentEnqueuedSearchAtom = atom(async (get) => {
  const searches = await get(izSearchesAtom);
  const enqueuedIzSearchResult = await get(enqueuedIzSearchAtom);
  if (enqueuedIzSearchResult === null) {
    return null;
  }
  const enqueuedIzSearchUid = enqueuedIzSearchResult.uid;

  return searches.find((s) => s.uid === enqueuedIzSearchUid);
});

export const refreshingRecordsAtom = atom(
  async (get): Promise<LegacyGetRecordDataDtoWithIntId[]> => {
    const verifiedSearches = await get(verifiedSearchesAtom);
    const refreshingRecordsList = [];

    for (const search of verifiedSearches) {
      const izRecordAtom = izRecordAPIResultAtomFamily(search.uid);
      const izRecord = await get(izRecordAtom);

      if (
        izRecord &&
        (izRecord.attributes.dequeued_at === undefined || izRecord.attributes.dequeued_at === null)
      ) {
        refreshingRecordsList.push(izRecord);
      }
    }

    return refreshingRecordsList;
  }
);

export const refreshingSearchesOrRecordsAtom = atom(async (get): Promise<boolean> => {
  const izses = await get(izSearchAPIResultAtom);
  const searchRefreshing =
    izses.find(
      (s) => s.attributes.dequeued_at === undefined || s.attributes.dequeued_at === null
    ) !== undefined;
  const recordRefreshing = (await get(refreshingRecordsAtom)).length > 0;
  return searchRefreshing || recordRefreshing;
});

// Loadable utility -- this allows us to use better loading states for async derivatives
export const loadableIzSearchesAtom = loadable(izSearchesAtom);
export const loadableCompleteRecordsAtom = loadable(completeRecordsAtom);
export const loadableCompleteVerifiedRecordsAtom = loadable(completeVerifiedRecordsAtom);
