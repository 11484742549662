import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { LayoutCard } from "../../components/onboarding/layoutCard/LayoutCard";
import "./signup.css";
import { getAPIClient } from "../../apiClient";
import { useBranding } from "../../branding/useBranding";
import { useTranslation } from "react-i18next";
import logout from "../../utils/logout";
import { getAuth } from "firebase/auth";
import { stopPolling } from "../../utils/polling";

export function SignUpTemplate() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const branding = useBranding();

  async function signOut() {
    stopPolling();
    await logout(getAPIClient(), getAuth());
    navigate("/");
  }

  return (
    <section className="section hero height-fitcontent">
      <Link
        to="/"
        className={`mt-6-desktop mb-4 ml-6-desktop is-${branding.backlinkColor}-text`}
        onClick={() => signOut()}
      >
        {t("onboarding.signup-back-button")}
      </Link>
      <LayoutCard>
        <div className="columns">
          <div className="column is-half p-6 is-flex is-flex-direction-column">
            <Outlet />
          </div>
          <div className="column is-half is-hidden-mobile signup-image">
            <img
              src="/assets/images/Docket_Logo_Blue.png"
              alt="Docket logo"
              className="docket-signup-image"
            />
            <img className="branding-image" src={`/${branding.loginBackgroundImage}`} />
          </div>
        </div>
      </LayoutCard>
    </section>
  );
}

export default SignUpTemplate;
