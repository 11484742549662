import { refreshingSearchesOrRecordsAtom } from "../jotai/atoms";
import { updateSearchAtoms } from "../jotai/atomUpdater";
import { globalStore } from "../jotai/store";

/**
 * A helper to ensure we only poll the backend for new data via a single chain of API calls that exponentially back
 * off. This should be used with a singleton pattern (we should only have one instance of PollingHelper exposed).
 */
class PollingHelper {
  #curRefresh: { id: string; promise: Promise<void> } | null;
  readonly #maxBackoffMs: number;
  #globalAttempts: number = 0;

  constructor() {
    this.#curRefresh = null;
    this.#maxBackoffMs = 1000 * 60; // 1 minute
  }

  stop() {
    /**
     * By incrementing the global attempts, we should be able to trick any active refreshes into thinking a "newer"
     * refresh has been spawned and thus they will stop making requests and resolve.
     */
    this.#globalAttempts = this.#globalAttempts + 1;
  }

  refresh(force: boolean): Promise<void> {
    if (!this.#curRefresh || force) {
      this.#curRefresh = this.internalRefresh();
    }
    return this.#curRefresh.promise;
  }

  private internalRefresh(): { id: string; promise: Promise<void> } {
    const id = window.crypto.randomUUID();
    const promise = new Promise<void>(async (resolve, reject) => {
      try {
        let stillRefreshing = true;
        let attempt = 0;
        while (stillRefreshing) {
          const globalAttemptsSeen = this.#globalAttempts;
          const backoff = this.calculateBackoff(attempt);
          await new Promise((resolve) => {
            setTimeout(resolve, backoff);
          });
          if (globalAttemptsSeen !== this.#globalAttempts) {
            /**
             * If the global attempts has changed since we started this iteration, we break before making any
             * requests. This condition implies another internal refresh has been spawned, and we should cede authority
             * to that refresh.
             */
            break;
          }
          this.#globalAttempts += 1;
          /** This updates the records AND screening atoms in addition to the searches. */
          const result = await updateSearchAtoms(false);
          stillRefreshing = (await globalStore.get(refreshingSearchesOrRecordsAtom)) || !result;
          attempt += 1;
        }
      } catch (e) {
        reject(e);
      } finally {
        /**
         * We only reset the curRefresh if we (ie this refresh) is still the current refresh.
         */
        if (this.#curRefresh?.id === id) {
          this.#curRefresh = null;
        }
      }
      resolve();
    });
    return { id, promise };
  }

  private calculateBackoff(attempt: number): number {
    // Math.pow won't overflow; it'll go to Infinity instead.
    return attempt === 0
      ? 0
      : Math.min(this.#maxBackoffMs, Math.pow(2, attempt) * 10) + Math.random() * 10;
  }
}

let refresher: null | PollingHelper = null;

/**
 * When called, the users's searches, records, and screenings (ie all PHI data)
 * will be updated asynchonously.
 */
export function fetchRecordsUntilUpdated(forceImmediateUpdate: boolean = false) {
  if (!refresher) {
    refresher = new PollingHelper();
  }
  return refresher.refresh(forceImmediateUpdate);
}

/**
 * Stops any active polling used to refresh a user's searches, records, and screenings.
 */
export function stopPolling() {
  if (refresher) {
    refresher.stop();
  }
}
