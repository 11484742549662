import {
  LegacyProviderConfigDto,
  ScreeningDto,
  TokenPairDto,
  UserResponseDto,
} from "@docket/consumer-app-client-typescript-axios";

export type SCREENING_TYPE = "lead";

export interface ImmunizationsState {
  gaveConsent: Boolean;
  patients: ImmunizationPatient[];
  reports: ImmunizationPatientReport[];
  searches: ImmunizationSearch[];
  search?: ImmunizationSearch;
  verifiedSearchId?: Number;
  pinAttempts: Number;
  error: string;
  searchQueue: ImmunizationSearchesEnqueued[];
  recordsQueue: ImmunizationRecordsEnqueued[];
  updateRecordsQueue: ImmunizationRecordsEnqueued[];
  providerConfigs: LegacyProviderConfigDto[];
  njFeatureFlag: boolean;
  providerLabels: string[];
}

export interface ImmunizationNewSearch {
  firstName: string;
  lastName: string;
  legalSex: string;
  zipcode?: string;
  dateOfBirth: string;
  childFirstName?: string;
  childLastName?: string;
  childLegalSex?: string;
  childDateOfBirth?: string;
  izProviderKey: string;
  whoAmI: string;
}

export interface ImmunizationSearch {
  firstName: string;
  lastName: string;
  legalSex: string;
  dateOfBirth: string;
  zipcode?: string;
  isChildSearch: boolean;
  childFirstName: string;
  childLastName: string;
  childLegalSex: string;
  childDateOfBirth: string;
  dateUpdated: string;
  dateVerified: string;
  uid: string;
  izProviderId: number;
  izProviderKey: string;
  whoAmI: string;
  status: string;
  recordsFound: number;
  patients: ImmunizationPatient[];
  contacts?: ImmunizationPatientContact[];
}

export interface ImmunizationSearchesEnqueued {
  estimated_wait_time: number;
  // TODO Do something with this?
  uid: string;
}

export interface ImmunizationRecordsEnqueued {
  estimated_wait_time: number;
  // TODO Do something with this?
  uid: string;
}

export interface ImmunizationRecord {
  uid: string;
  searchUid: string;
  izProviderId: number;
  izProviderKey: string;
  patientId: string;
  immunizations: ImmunizationSeries[];
  // Names should be pulled from the search
  firstName: string;
  lastName: string;
  createdAt: string;
  updatedAt?: string;
  discardedAt?: string;
  dequeuedAt?: string;
}

// This contains all info for a single series, e.g. Polio, HIB, Flu, etc.
// It's possible that a 'series' has a status but no events
// This is when a user has only a forecast - e.g. for an infant, or someone that's never
// gotten their flu vaccine, etc.
// Events should always exist, but may be empty.
export interface ImmunizationSeries {
  searchUid: string;
  recordUid: string;
  vaccineType: string;
  status: string;
  events: ImmunizationSeriesEvent[];
  dateDue?: string;
  dateOverdue?: string;
  earliestDateToGive?: string;
  latestDateToBeGiven?: string;
}

export interface ImmunizationSeriesEvent {
  dateAdministered?: string;
  doseNumberInSeries?: string;
  manufacturer: string;
  recordId: string;
  lot: string;
  description: string;
  administeredAtFacility: string;
  administeredAtStreet: string;
  administeredAtCity: string;
  administeredAtState: string;
  administeredAtZip: string;
}

export interface ImmunizationPatient {
  firstName: string;
  lastName: string;
  patientId: string;
  userId?: number;
  dob: string;
  searchUid: string;
  izProviderId: number;
  izProviderKey: string;
  records?: ImmunizationRecord[];
  searches?: ImmunizationSearch[];
  screenings?: ScreeningRecord[];
  contacts?: ImmunizationPatientContact[];
}

export interface ImmunizationPatientContact {
  type: string;
  value: string;
  maskedValue?: string;
}

export interface ImmunizationPatientReport {
  reportName: string;
  reportStatus: string;
  filePath: string;
}

export interface UserNotificationPreferences {
  disable_all: boolean;
  email_upcoming_vaccines: boolean;
  email_app_updates: boolean;
  email_new_results: boolean;
  push_upcoming_vaccines: boolean;
  push_app_updates: boolean;
  push_new_results: boolean;
}

export type Tokens = TokenPairDto;

export type UserInfo = Omit<UserResponseDto, "tokens">;

export interface Login {
  email: string;
  password: string;
}

export interface ForgotPassword {
  email: string;
}

export interface Signup {
  email: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  password: string;
  passwordConfirmation: string;
}

export interface AppleSignUp {
  token: string;
  code: string;
  email?: string;
  first_name?: string;
  last_name?: string;
}

export interface GoogleSignup {
  email: string;
  first_name: string;
  last_name: string;
  middle_name?: string;
  token: string;
  google_id: string;
  offset: string; //NSTimeZone.system.secondsFromGMT() equivalent
}

export interface AppleOauthResponse {
  error: { message: string; stack: string };
  authorization?: { code: string; id_token: string };
  user?: {
    email: string;
    name: { first_name: string; last_name: string };
  };
}

export interface ScreeningRecord {
  searchUid: string;
  izProviderId: number;
  izProviderKey: string;
  results: ScreeningResult[];
  // Name should be pulled from the search
  firstName: string;
  lastName: string;
}

export interface ScreeningResult {
  type: SCREENING_TYPE;
  events: ScreeningDto[];
  latestTestDate?: string;
}

export enum SearchStatus {
  match = "MATCH",
  noMatch = "NO_MATCH",
  partialMatchNoContacts = "PARTIAL_MATCH_NO_CONTACTS",
  partialMatchAltContacts = "PARTIAL_MATCH_ALT_CONTACTS",
  basicMatchNoContacts = "BASIC_MATCH_NO_CONTACTS",
  multiMatch = "MULTI_MATCH",
  noRecords = "NO_RECORDS",
  inQueue = "IN_QUEUE",
}

export interface AppSettings {
  firebaseEmulatorConnected: boolean;
  email: string;
  authorized: boolean;
  signedInWithFirebase: boolean;
}
