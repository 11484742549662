import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ScreeningRecord, ScreeningResult } from "../../models/Interfaces";
import getIzProvider from "../../models/providers/IzProviders";
import "../immunizations/events.css";
import "./events.css";
import ScreeningEventCard from "../../components/ScreeningEventCard";
import { useTranslation } from "react-i18next";
interface EventLocationState {
  screening: ScreeningRecord;
  screeningResult: ScreeningResult;
}

function ScreeningFaq() {
  const { state } = useLocation();
  const { screening } = (state as EventLocationState) || {};

  const { t } = useTranslation();
  const provider = getIzProvider(screening.izProviderKey, t);
  return (
    <div className="box event-card has-text-dark mb-5 screening-faq">
      <div className=" columns is-gapless is-vcentered ">
        <div className="column">
          <div className="mb-4">
            <h2 className="title is-4">{t(`screening.lead_info_section_title`)}</h2>
          </div>
          <div className="mb-4">
            <h2 className="title is-5">{t(`screening.lead_info_title_1`)}</h2>
            <div className="ml-4">
              <ul>
                <li>
                  <p>{t(`screening.lead_info_section_1_1`)}</p>
                </li>
              </ul>
            </div>
          </div>

          <div className="mb-4">
            <h2 className="title is-5">{t(`screening.lead_info_title_2`)}</h2>
            <div className="ml-4">
              <ul>
                <li>
                  <p>{t(`screening.lead_info_section_2_1`)}</p>
                </li>
                <li>
                  <p>{t(`screening.lead_info_section_2_2`)}</p>
                </li>
              </ul>
            </div>
          </div>

          <div className="mb-4">
            <h2 className="title is-5">{t(`screening.lead_info_title_3`)}</h2>
            <div className="ml-4">
              <ul>
                <li>
                  <p>{t(`screening.lead_info_section_3_1`)}</p>
                </li>
                <li>
                  <p>
                    {t(`screening.lead_info_section_3_2a`)}
                    <span className="has-text-weight-bold">
                      {t(`screening.lead_info_section_3_2b`)}
                    </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <p>{t(`screening.lead_info_section_footer_1`)}</p>
            <p>
              {t(`screening.lead_info_section_footer_2`)}
              <a href={provider.screeningResultLink} style={{ fontSize: `0.90rem` }}>
                {provider.screeningResultLink}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function ScreeningEvents() {
  const { state } = useLocation();
  const { screening, screeningResult } = (state as EventLocationState) || {};
  const { t } = useTranslation();

  return (
    <div className="section">
      <h2 className="has-text-dark is-size-4 has-text-centered has-text-weight-bold pb-2">
        {`${screening.firstName || ""} ${screening.lastName || ""}`}
      </h2>
      <p className="has-text-dark is-size-5 has-text-centered has-text-weight-bold pb-2">
        {t("screening.lead_screening_test")}
      </p>
      <br />
      <br />
      <ul>
        {screeningResult.events.map((e, index) => (
          <li className="mb-4" key={index}>
            <ScreeningEventCard index={index} screeningEvent={e} />
          </li>
        ))}

        <li>
          <ScreeningFaq />
        </li>
      </ul>
    </div>
  );
}
