import { getAPIClient, LegacyGetRecordDataDtoWithIntId } from "../apiClient";
import {
  appSettingsAtom,
  izRecordAPIResultAtomFamily,
  izSearchAPIResultAtom,
  screeningsAPIResultAtomFamily,
  userAtom,
  verifiedSearchesAtom,
} from "./atoms";
import { errorLog, warnLog } from "../utils/logger";
import { globalStore } from "./store";
import Bugsnag from "@bugsnag/js";
import { hasScreeningsEnabled } from "../globals";

export async function updateSearchAtoms(refresh: boolean): Promise<boolean> {
  try {
    const searches = await getAPIClient().getIzSearches(refresh);
    if (!searches.data) {
      return true;
    }

    await globalStore.set(izSearchAPIResultAtom, searches.data);
    // If we updated the searches we should also update the records
    return updateRecordAtoms();
  } catch (error: any) {
    // Handle error here, should be silent though
    errorLog(error, "Could not update search atoms");
    return false;
  }
}

export async function updateRecordAtoms(): Promise<boolean> {
  try {
    const searches = await globalStore.get(verifiedSearchesAtom);

    if (!searches) {
      return true;
    }

    for (const search of searches) {
      try {
        // Use the family of atoms for records
        const records: LegacyGetRecordDataDtoWithIntId = await getAPIClient().getIzRecord(
          search.uid,
          search.izProviderKey
        );
        const izRecordAPIResultAtom = izRecordAPIResultAtomFamily(search.uid);
        await globalStore.set(izRecordAPIResultAtom, records);
      } catch (e: any) {
        if (e instanceof Error) {
          errorLog(e, `Could not retrieve record for search ${search.uid}: ${e.message}`);
          Bugsnag.notify(e);
        } else {
          warnLog(`Could not retrieve record for search ${search.uid}: ${e}`);
        }
      }
    }

    return await updateScreeningAtom();
  } catch (error: any) {
    errorLog(error, "Could not update record atoms");
    return false;
  }
}

export async function updateScreeningAtom(): Promise<boolean> {
  try {
    const searches = await globalStore.get(verifiedSearchesAtom);
    if (!searches) {
      return true;
    }

    const user = await globalStore.get(userAtom);
    // Screenings disabled? Return immediately.
    if (!hasScreeningsEnabled(user?.email || "")) {
      return true;
    }

    for (const search of searches) {
      try {
        const screenings = await getAPIClient().getIzScreeningRecord(
          search.uid,
          search.izProviderKey
        );
        const izScreeningAPIResultAtom = screeningsAPIResultAtomFamily(search.uid);
        await globalStore.set(izScreeningAPIResultAtom, screenings);
      } catch (e: any) {
        if (e instanceof Error) {
          errorLog(e, `Could not retrieve screenings for search ${search.uid}: ${e.message}`);
          Bugsnag.notify(e);
        } else {
          warnLog(`Could not retrieve screenings for search ${search.uid}: ${e}`);
        }
      }
    }

    return true;
  } catch (error: any) {
    errorLog(error, "Could not update screening atom");
    return false;
  }
}

export async function clearPrefs(): Promise<void> {
  // We want to preserve the firebase emulator setting and whether or not this has NJ features
  const currentSettings = await globalStore.get(appSettingsAtom);
  await globalStore.set(appSettingsAtom, {
    ...currentSettings,
    email: "",
    authorized: false,
    signedInWithFirebase: false,
  });
}
