import { TokenPairDto } from "@docket/consumer-app-client-typescript-axios";
import db, { Key } from "../../database";
import { Tokens, UserInfo } from "../../models/Interfaces";

export default async () => {
  /**
   * XXX: This method reads directly from the database instead of using the Jotai atom for tokens. This is done because
   * the Jotai storage atoms are initialized with a default value of `null`. That means if you log in and then refresh
   * the page, you'll get `null` from the Atom. Because this code sits outside of any React component, it's a bit harder
   * to control and so we fallback to reading from the database.
   */
  let tokens = await db().getItem<Tokens>(Key.Tokens);
  /**
   * If the user updates the app from a version where we stored tokens alongside the user info WITHOUT triggering a
   * window reload then the tokens key will not yet be populated. In this case, we can attempt to migrate the existing
   * tokens from the user info and return those.
   */
  if (!tokens) {
    const user = await db().getItem<UserInfo & { tokens?: TokenPairDto }>(Key.UserInfo);
    const userTokens = user?.tokens;
    if (userTokens) {
      await db().setItem(Key.Tokens, userTokens);
      tokens = userTokens;
    }
  }
  return tokens;
};
