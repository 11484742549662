import React from "react";
import { useLocation } from "react-router-dom";
import { ImmunizationRecord, ImmunizationSeries } from "../../models/Interfaces";
import EventCard from "../../components/EventCard";
import moment from "moment";
import getIzProvider, { IzProvider } from "../../models/providers/IzProviders";
import { getStatusColor, getStatusLabel } from "../../utils/status";
import "./events.css";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

interface EventLocationState {
  record: ImmunizationRecord;
  series: ImmunizationSeries;
}

const calcDueDateText = (immSeries: ImmunizationSeries | null | undefined) => {
  const { t } = useTranslation();
  if (!immSeries) {
    return "";
  }

  if (immSeries.dateDue && immSeries.dateOverdue) {
    return (
      <>
        {moment(immSeries.dateDue).format("L")} - {moment(immSeries.dateOverdue).format("L")}
      </>
    );
  }
  if (immSeries.dateOverdue) {
    return (
      <>
        <div className="text-bold">{t("immunizations.records_overdue_after")} </div>
        {moment(immSeries.dateOverdue).format("L")}
      </>
    );
  }
  if (immSeries.dateDue) {
    return (
      <>
        <div className="text-bold">{t("immunizations.records_due_on_or_after")} </div>
        {moment(immSeries.dateDue).format("L")}
      </>
    );
  }

  return "";
};

export const EventHeaderCard = ({
  immSeries,
  provider,
  t,
}: {
  immSeries: ImmunizationSeries;
  provider: IzProvider;
  t: TFunction;
}) => {
  const dueDate = calcDueDateText(immSeries);
  const color = getStatusColor(provider, immSeries?.status.toLowerCase(), immSeries);
  const label = t("immunizations.records_status", {
    context: getStatusLabel(provider, immSeries?.status.toLowerCase(), immSeries),
  });
  return (
    <div
      className="event-card-header has-text-centered pb-2"
      style={{ backgroundColor: "#3d7bb3" }}
    >
      <h3 className="is-size-4">{immSeries.vaccineType}</h3>
      <div
        className={`${color} status-badge pt-1 pb-1 pr-2 pl-2 has-text-weight-bold`}
        style={{ display: "flex", alignItems: "center" }}
      >
        <p>
          {label.toUpperCase()}
          {dueDate && <p>{dueDate}</p>}
        </p>
      </div>
    </div>
  );
};

export default function Events() {
  const { state } = useLocation();
  const { record, series } = (state as EventLocationState) || {};
  const { t } = useTranslation();

  const provider = getIzProvider(record.izProviderKey, t);
  return (
    <div className="section">
      <h2 className="has-text-dark is-size-4 has-text-centered has-text-weight-bold pb-2">
        {`${record?.firstName || ""} ${record?.lastName || ""}`}
      </h2>
      <br />
      <EventHeaderCard immSeries={series} provider={provider} t={t} />
      <br />
      <ul>
        {series?.events?.map((e, index) => (
          <li className="mb-4" key={index}>
            <EventCard index={index} event={e} vaccine={series.vaccineType} />
          </li>
        ))}
      </ul>
    </div>
  );
}
